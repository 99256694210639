import { DateUtils, OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props extends BoxProps {
  orderSummary: OrderSummary,
}

const OrderCreatedAt = ({ orderSummary, ...boxProps }: Props) => {
  const { t } = useTranslations()

  return (
    <Box {...boxProps}>
      <Typography variant='body1'>
        {t('orders.orderDate')}
      </Typography>
      <Typography variant='h6'>
        {DateUtils.calendar(new Date(orderSummary.createdAt)).toLowerCase()}
      </Typography>
    </Box>
  )
}

export default OrderCreatedAt
